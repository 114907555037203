import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Row, Col, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom/client';
var ErrorAlert = (props) => {
    return (
        <Modal>
            <Modal.Header closeButton className='p-1 rounded-0'>
                {props.header === undefined || props.header === null ? 'Error' : props.header}
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center'>
                    {props.message}
                </div>
            </Modal.Body>
        </Modal>
    )
}
export function Error(props) {
    return (<Modal show={true}  onHide={()=>{
        props.onHide()
    }}>
        <Modal.Header className='bg-danger rounded-0 text-white h4' closeButton>
            {props.header === undefined || props.header === null ? 'Error' : props.header}
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex justify-content-center'>
                {props.message}
            </div>
        </Modal.Body>
    </Modal>)
}

