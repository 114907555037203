import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

export default function CurrencyChooser(props){
    const currency=props.currency;
    return(        <Modal className='rounded-0' show={props.show_currency_modal} onHide={() => {
        props.selectCurrency(false);
    }
    } >
        <Modal.Header closeButton className='p-1 bg-primary rounded-0 text-white'>
            Select Currency
        </Modal.Header>

        <Modal.Body>

            {['USD', 'RTGS', 'RANDS'].map((cu) => (
              <Form.Check onChange={() => {
                    if (currency.filter(e => e === cu).length > 0) {
                        props.setCurrency(currency.filter(e => e !== cu))
                    } else {
                        var v = currency;
                        v.push(cu)
                        props.setCurrency(v)
                    }
                }} type='checkbox' key={cu} defaultChecked={
                    currency.filter(e => e === cu).length > 0
                } label={cu} />
            ))}
        </Modal.Body>
        <Modal.Footer>
            <Button variant='primary' onClick={() => {
                props.selectCurrency(false)
            }} size='sm'>Done</Button>
        </Modal.Footer>
    </Modal>)
}