
import React, { useEffect, useState } from 'react';
import Home from './pages/Home';
import Quotation from './pages/newQuotation';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { QuotationConfig } from "./pages/config_quotation";
import { useLocation } from 'react-router-dom';
import { Card } from "react-bootstrap";
import {RecentQuotes} from './pages/previous_quotes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Modal, Row, Col, Table } from 'react-bootstrap';

function App() {
  const navigate = useNavigate();
  let pathname = useLocation().pathname;
  const [BackButton, showBackBUtton] = useState(true);
  const [notice, setNotice] = useState(false);
  const [loggin, setLoggin] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    showBackBUtton(pathname !== '/' || pathname === '/index.html');
  }, [pathname])
  return (
    <>
    <Modal show={loggin}>
                <Modal.Header className='p-1 p-2 rounded-0'>
                    <div className='strong h3 text-primary'>Log In To proceed</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='justify-content-center'>
                        {notice?<span className='text-danger'>Password or Email is Incorrect!!</span>:null}
                        <div class="form-outline mb-4">
                            <input type="email" value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} id="form2Example1" className="form-control" />
                            <label className="form-label" for="form2Example1">Email address</label>
                        </div>
                        <div class="form-outline mb-4">
                            <input type="password" value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }} id="form2Example2" className="form-control" />
                            <label className="form-label" for="form2Example2">Password</label>
                        </div>
                        <button className=' btn btn-primary' onClick={() => {
                            if (password == "test123" && email == "carronmuleya10@gmail.com") {
                                setLoggin(false)
                            } else {
                                setNotice(true);
                            }
                        }}>Login</button>
                    </div>
                </Modal.Body>

            </Modal>
      <Card className="rounded-0">
        <Card.Header className="rounded-0 p-1 m-1" ><div className="d-flex justify-content-between">
          <div>{BackButton ? <a onClick={
            () => {
              navigate(-1);
            }
          } className=" text-primary mouse-pointer"  href="#!" as="a" style={{ textDecoration: 'none' }}>
            <FontAwesomeIcon size="2x" className="mouse-pointer" icon={faArrowLeft} />
          </a> : null}</div>
          {/* <div><p className="small text-muted px-2">Logged In As: Carron Muleya</p></div> */}
        </div></Card.Header>
        <Card.Body className="py-0 my-0">
          <Routes>
            <Route exact index path='/' element={<Home/>} />
            <Route path='/quotation' element={<Quotation />} />
            <Route path="/config_quotation" element={<QuotationConfig />} />
            <Route path="/existing_quotes" element={<RecentQuotes/>}/>
          </Routes>
        </Card.Body>
      </Card>
    </>
  );
}

export default App;
