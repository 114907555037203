import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BaobabSparLogo from '../BaobabSparLogo.png';

export default function QuotationPreview(props) {
    const componentRef = useRef();
    let params = useLocation()
    const currency = props.currency,
        quotedProducts = props.quotedProducts,
        RtgsTotal = props.RtgsTotal,
        RandsRate = props.RandsRate,
        usdRate = props.usdRate;

    return (<Modal fullscreen={true} className='rounded-0 border border-primary quotation' size='lg' show={props.previewQuotation} onHide={() => {
        props.setPreviewQuotation(false)
    }
    } >
        <Modal.Header closeButton className='p-1 rounded-0 bg-light '>
            <span className='h5 ml-2'>Quotation Preview
            </span>
            <Button variant='danger' className='mx-2 ms-2' onClick={
                useReactToPrint({
                    content: () => componentRef.current,
                })
            } size='sm'>Print Quotation</Button>
        </Modal.Header>
        <Modal.Body>
            <div className='px-5' ref={componentRef}>
                <div className="mt-5">
                    <div className="row">
                        <div className="col">
                            <img height="230px" width="250px" className="ms-5 mt-2 mb-4 pt-4 ps-5" alt='logo' src={BaobabSparLogo}></img>
                        </div>
                        <div className="ms-5 mt-4 col">
                            <span className="h6">N &amp; R ENTERPRISES T/A <br />BAOBAB SPAR<br />SHOP NUMBER 14<br />ZESA
                                COMPLEX,BEITBRIDGE,ZIMBABWE<br />VAT NUMBER:10001568<br />CONTACT -0719716507<br /><br />DATE OF QUOTE :
                                17/03/2023<br /></span>
                        </div>
                    </div>
                    <div className="py-2 text-uppercase" style={{ fontSize: 18, fontWeight: "bolder" }}>
                        {
                            props.quote != null ? props.quote.customer : null
                        }
                    </div>
                    <div>
                        <table className="table">
                            <thead className="mn23">
                                <tr>
                                    <td>SALESPERSON</td>
                                    <td>Comp-Ref</td>
                                    <td colSpan="2">RECEIVED BY</td>

                                    {
                                        (() => {
                                            if (currency.includes('RTGS')) {
                                                return (
                                                    <>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if (currency.includes('RANDS')) {
                                                return (
                                                    <>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }


                                    {
                                        (() => {
                                            if (currency.includes('USD')) {
                                                return (
                                                    <>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    {
                                        (() => {
                                            if (currency.includes('RANDS')) {
                                                return (
                                                    <>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('USD')) {
                                                return (
                                                    <>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                </tr>
                                <tr className="mn23">
                                    <td>Item</td>
                                    <td>DESCRIPTION</td>

                                    <td>QTY</td>
                                    <td></td>
                                    {
                                        (() => {
                                            if (currency.includes('RTGS')) {
                                                return (
                                                    <>
                                                        <td>UNIT PRICE INC(RTGS)</td>
                                                        <td>LINE TOTAL INC(RTGS)</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('RANDS')) {
                                                return (
                                                    <>
                                                        <td>UNIT PRICE INC(ZAR)</td>
                                                        <td>LINE TOTAL INC(ZAR)</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if (currency.includes('USD')) {
                                                return (
                                                    <>
                                                        <td>UNIT PRICE INC(USD)</td>
                                                        <td>LINE TOTAL INC(USD)</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                </tr>
                                {
                                    quotedProducts.map((item, i) =>
                                    (
                                        <tr className='small'>
                                            <td>{1 + i}</td>

                                            <td>
                                                {item.Name}
                                            </td>
                                            <td>{item.qty}</td>
                                            <td></td>

                                            {
                                                (() => {
                                                    if (currency.includes('RTGS')) {
                                                        return (
                                                            <>
                                                                <td>{item['Selling Price']}</td>
                                                                <td>{Math.round(((item.qty * item['Selling Price']) + Number.EPSILON) * 100) / 100}</td>
                                                            </>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if (currency.includes('RANDS')) {
                                                        return (
                                                            <>
                                                                <td>{Math.round((((item['Selling Price']) / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                                                <td>{Math.round((((item.qty * item['Selling Price']) / RandsRate) + Number.EPSILON) * 100) / 100}
                                                                </td>
                                                            </>
                                                        )
                                                    }
                                                })()
                                            }

                                            {
                                                (() => {
                                                    if (currency.includes('USD')) {
                                                        return (
                                                            <>
                                                                <td>{Math.round((((item['Selling Price']) / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                                                <td>{Math.round((((item.qty * item['Selling Price']) / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                                            </>
                                                        )
                                                    }
                                                })()
                                            }

                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td rowSpan="3" colSpan="2">
                                        <span>NB:KINDLY NOTE THAT THIS QUOTATION IS ONLY VALID FOR 24 HOURS AS PRICES CHANGE
                                            AFTER SPECIFIED PERIOD.<br />THANK YOU</span>
                                    </td>
                                    <td className="mn23" colSpan="2">SUB-TOTAL</td>


                                    {
                                        (() => {
                                            if (currency.includes('RTGS')) {
                                                return (
                                                    <>
                                                    <td className="mn23"></td>
                                                        <td className="mn23">ZW${Math.round(((RtgsTotal) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('RANDS')) {
                                                return (
                                                    <>
                                                        <td className="mn23"></td>
                                                        <td className="mn23">ZAR{Math.round(((RtgsTotal / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('USD')) {
                                                return (
                                                    <>
                                                        <td className="mn23"></td>
                                                        <td className="mn23">US${Math.round(((RtgsTotal / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                </tr>
                                <tr className="mn23">
                                    <td className="mn23" colSpan="2">SUB TOTAL EXCLUSSIVE</td>
                                    {
                                        (() => {
                                            if (currency.includes('RTGS')) {
                                                return (
                                                    <>
                                                     <td className="mn23"></td>
                                                        <td className="mn23">ZW${Math.round(((RtgsTotal) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('RANDS')) {
                                                return (
                                                    <>
                                                        <td className="mn23"></td>
                                                        <td>ZAR{Math.round(((RtgsTotal / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (currency.includes('USD')) {
                                                return (
                                                    <>
                                                        <td className="mn23"></td>
                                                        <td>US${Math.round(((RtgsTotal / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <div className=" d-flex justify-content-between">
                            <div></div>
                            <div>
                                COMPANY:N &amp; R ENTERPRISES (Pvt) Ltd t/a BAOBAB SPAR<br />
                                BANK : CBZ BANK<br />
                                RTGS ACCOUNT<br />
                                ACCOUNT #: 09321239250064<br />
                                BRANCH: BEITBRIDGE<br />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal>)
}