import { faBucket, faDeleteLeft, faEdit, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import EditProduct from './EditQuotedProduct';

export default function QuotationPreview(props) {
    const table_btm = useRef(), table = useRef();
    const [prodEditor,setProdEditor]=useState(null);
    const currency = props.currency,
        quotedProducts = props.quotedProducts,
        RtgsTotal = props.RtgsTotal,
        RandsRate = props.RandsRate,
        usdRate = props.usdRate, [tbodyHeight, setheight] = useState();
    useEffect(() => {
        setheight(table_btm.current.offsetTop - (table.current.offsetTop + table.current.offsetHeight))
    }, [table, table_btm, window])
    return (<div className='quotation-preview mt-1 border rounded-top' >
        <Table striped hover ref={table}>
            <thead className='bg-primary text-white rounded-top'>
                <tr className='small' key={1}>
                    <td>
                        <p>Remove/Edit</p>
                    </td>
                    <td>Description</td>
                    <td>Quantity</td>
                    {
                        (() => {
                            if (currency.includes('RTGS')) {
                                return (
                                    <> <td>
                                        Unit Price(RTGS)
                                    </td>
                                        <td>
                                            Total Price(RTGS)
                                        </td>
                                    </>
                                )
                            }
                        })()
                    }
                    {(() => {
                        if (currency.includes('RANDS')) {
                            return (
                                <> <td>
                                    Unit Price(RAND)
                                </td>
                                    <td>
                                        Total Price(RAND)
                                    </td>
                                </>
                            )
                        }
                    })()}{
                        (() => {
                            if (currency.includes('USD')) {
                                return (
                                    <> <td>
                                        Unit Price(USD)
                                    </td>
                                        <td>
                                            Total Price(USD)
                                        </td>
                                    </>
                                )
                            }
                        })()
                    }
                </tr>
            </thead>

            <tbody style={{ height: tbodyHeight }}>
                {
                    quotedProducts.map((item, i) =>
                    (
                        <tr key={i} className='small'>
                            <td>
                                <a  onClick={
                                    (e) => {
                                        props.delete(item, i)
                                    }
                                } className='btn-link btn-primary p-1'><FontAwesomeIcon size='1x' icon={faRemove} /></a>
                                <a onClick={
                                    (e) => {
                                       
                                        setProdEditor([item,i])
                                    }
                                } className='btn-link btn-primary p-1'><FontAwesomeIcon size='1x' icon={faEdit} /></a>
                            </td>
                            <td>
                                {item.Name}
                            </td>
                            <td>{item.qty}</td>



                            {
                                (() => {
                                    if (currency.includes('RTGS')) {
                                        return (
                                            <>
                                                <td>{item['Selling Price']}</td>
                                                <td>{Math.round(((item.qty * item['Selling Price']) + Number.EPSILON) * 100) / 100}</td>
                                            </>
                                        )
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if (currency.includes('RANDS')) {
                                        return (
                                            <>
                                                <td>{Math.round((((item['Selling Price']) / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                                <td>{Math.round((((item.qty * item['Selling Price']) / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                            </>
                                        )
                                    }
                                })()
                            }

                            {
                                (() => {
                                    if (currency.includes('USD')) {
                                        return (
                                            <>
                                                <td>{Math.round((((item['Selling Price']) / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                                <td>{Math.round((((item.qty * item['Selling Price']) / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                            </>
                                        )
                                    }
                                })()
                            }

                        </tr>
                    ))
                }
            </tbody>
            <tfoot className='fixed-bottom mx-3 rounded-bottom' ref={table_btm}>
                <tr key={1} className='text-uppercase bg-dark text-white '>

                    <td>
                        Total
                    </td>
                    <td></td>
                    <td>
                    </td>


                    {
                        (() => {
                            if (currency.includes('RTGS')) {
                                return (

                                    <td>RTGS{Math.round(((RtgsTotal) + Number.EPSILON) * 100) / 100}</td>
                                )
                            }
                        })()
                    }
                    <td></td>

                    {
                        (() => {
                            if (currency.includes('RANDS')) {
                                return (
                                    <td>R{Math.round(((RtgsTotal / RandsRate) + Number.EPSILON) * 100) / 100}</td>
                                )
                            }
                        })()
                    }
                    <td></td>
                    {
                        (() => {
                            if (currency.includes('USD')) {
                                return (
                                    <td>USD{Math.round(((RtgsTotal / usdRate) + Number.EPSILON) * 100) / 100}</td>
                                )
                            }
                        })()
                    }
                </tr>
            </tfoot>
        </Table>
        {prodEditor!==null?<EditProduct onClose={()=>{
            setProdEditor(null)
        }} product={prodEditor} onSave={(p,i)=>{
            props.afterProdModification(p,i)
        }} />:null}
    </div>
    )
}