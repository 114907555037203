import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Row, Col, Table } from 'react-bootstrap';
// import { channels } from '../shared/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowLeftLong, faSearch } from '@fortawesome/free-solid-svg-icons'
// const electron = window.require('electron');
// const ipcRenderer = electron.ipcRenderer;

function search(what, where) {
    var half = where.length / 2;
}
function SelectGroup(props) {

    return (<Modal className='rounded-0' dialogClassName='shadow shadow-lg border border-secondary' backdrop='static' size='md' show={true} onHide={() => {
        props.hide()
    }} >
        <Modal.Header closeButton className='p-1 rounded-0'>
            List Groups
        </Modal.Header>
        <Modal.Body>
            <Button variant='primary' onClick={(e) => {
            }}  size='sm'>
                <FontAwesomeIcon icon={faArrowLeftLong} />
            </Button>

            <Row className='my-1'>


                <Col>
                    <div className='bg-dark text-white small'>Group Name</div>
                    <div><Form.Control className='rounded-0' size="sm"></Form.Control></div>
                </Col>

            </Row>
            <Table bordered striped hover className='pt-1'>
                <thead className='text-white h-5  bg-dark'>
                    <tr className='small'>
                        <td>
                            <Form.Check onChange={() => {
                            }} type='checkbox' />
                        </td>
                        <td>Code</td>
                        <td>
                            Name
                        </td>

                    </tr>
                </thead>
                <tbody>

                    <tr key={1}>
                        <td>
                            <Form.Check onChange={() => {
                            }} type='checkbox' />
                        </td>
                        <td>2000</td>
                        <td>Liquor</td>
                    </tr>
                </tbody>
            </Table>
        </Modal.Body>
    </Modal>)
}
export default function SelectProduct(props) {

    const allProducts = props.myProducts;
    const [myProducts, setMyProducts] = useState(props.myProducts);
    const [selectGroup, SetSelectGroup] = useState(false);
    const [searchGroups, setSearchGroups] = useState('');
    // const backPage = useRef();
    // const nextPage = useRef();
    const [currentPage, setCurrentPage] = useState(0);
    const [activePage, setActivePage] = useState([]),
        pagelimit = 13;
    useEffect(() => {

        var prod = [];
        for (let index = (currentPage * pagelimit); index < (currentPage * pagelimit) + pagelimit; index++) {
            const element = myProducts[index];
            if (element === undefined) {
                break;
            }
            prod.push(element)
        }
        setActivePage(prod)
    }, [currentPage])
    useEffect(() => {
        var prod = [];
        for (let index = (currentPage * pagelimit); index < (currentPage * pagelimit) + pagelimit; index++) {
            const element = myProducts[index];
            if (element === undefined) {
                break;
            }
            prod.push(element)
        }
        setActivePage(prod)
        setCurrentPage(0)
    }, [myProducts])
    return (
        <Modal className='rounded-0' backdrop='static' keyboard="false" tabIndex="-1" size='xl' show={true} onHide={() => {
            props.hide()
        }} >
            <Modal.Header closeButton className='p-1 rounded-0'>
                List Product Details
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <Button variant='primary' onClick={(e) => {
                            if (currentPage > 0) {
                                setCurrentPage(currentPage - 1)
                            }
                        }} className='mx-1' size='sm'>
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </Button>

                        <Button variant='primary' onClick={(e) => {
                            if (myProducts.length > (currentPage * pagelimit) + pagelimit) {
                                setCurrentPage(currentPage + 1)
                            }
                        }} className='mx-1' size='sm'>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>

                    </div>
                </div>
                {selectGroup !== true ? null : <SelectGroup hide={() => {
                    SetSelectGroup(false)
                }} callBack={(groups) => {

                }} />}
                <Row className='my-1'>
                    <Col>
                        <div className='bg-dark text-white small'>Order Code</div>
                        <div><Form.Control onKeyDown={(e) => {

                            if (e.key === "F9") {
                                var n = Number(e.target.value);
                                if (!isNaN(e.target.value) && e.target.value.length === 6) {
                                    for (let index = n - 20; index < n + 20; index++) {
                                        const element = allProducts[index];
                                        if (element === null || element === undefined) {
                                            continue;
                                        }

                                        if (element['Order Code'] === e.target.value) {

                                            setMyProducts([element])
                                            break;
                                        } else {
                                            setMyProducts([])
                                        }

                                    }
                                } else {
                                    setMyProducts([])
                                }

                            }
                        }}
                            className='rounded-0' size="sm">
                        </Form.Control></div>
                    </Col>
                    <Col>
                        <div className='bg-dark text-white small'>BarCode</div>
                        <div><Form.Control onKeyDown={(e) => {

                            if (e.key === "F9" || e.key === "Enter") {

                                var n = Number(e.target.value);

                                if (!isNaN(e.target.value)) {
                                    for (let index = 0; index < allProducts.length; index++) {
                                        const element = allProducts[index];
                                        if (element.BarCode === e.target.value) {

                                            setMyProducts([element])
                                            break;
                                        } else {
                                            setMyProducts([])
                                        }

                                    }
                                } else {
                                    setMyProducts([])
                                }

                            }
                        }} maxLength={16} className='rounded-0' size="sm"></Form.Control></div>
                    </Col>
                    <Col>
                        <div className='bg-dark text-white small'>Name</div>
                        <div><Form.Control className='rounded-0' onKeyDown={(e) => {
                            if (e.key === "F9" || e.key === "Enter") {
                                var productValue = e.target.value;
                                var prod = allProducts.filter(
                                    item => (item.Name.toLowerCase().match(productValue.toLowerCase()) || item.Name.toLowerCase().includes(' ' + productValue.toLowerCase() + ' ' || (new RegExp(productValue.toLowerCase(), 'g').test(item.Name))))
                                )
                                setMyProducts(prod)
                            }
                        }} size="sm"></Form.Control></div>
                    </Col>
                    <Col>
                        <div className='bg-dark text-white small'>Group</div>
                        <div className='input-group'>
                            <div className='input-group-preppend'>
                                <Button onClick={() => {
                                    SetSelectGroup(true)
                                }} variant='primary' className=' rounded-0' size='sm'>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </div>
                            <Form.Control value={searchGroups} onChange={(e) => {
                                setSearchGroups(e.target.value)
                            }} className='rounded-0' size="sm"></Form.Control>
                        </div>
                    </Col>
                </Row>
                <Table striped hover>
                    <thead className='bg-primary text-white'>
                        <tr className='small'>
                            <td></td>
                            <td>
                                Order Code
                            </td>
                            <td>
                                Name
                            </td>
                            <td>
                                Selling
                            </td>
                            <td>
                                On Hand
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activePage.map(item =>
                            (<tr key={item['Order Code']}>
                                <td>
                                    <Button variant='primary' className='text-white  p-1 rounded py-0' onClick={() => {
                                        props.callBack(item)
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </Button>

                                </td>
                                <td>
                                    {item['Order Code']}
                                </td>
                                <td>{item.Name}</td>
                                <td>{item['Selling Price']}</td>
                                <td> {item['On Hand']}</td>
                            </tr>))
                        }
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}