import React, { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Nav, Table, } from 'react-bootstrap';
import Spar from './spar';
import { useNavigate } from "react-router-dom";

import { channels } from "../shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRemove } from "@fortawesome/free-solid-svg-icons";
// const electron = window.require('electron');
// const ipcRenderer = electron.ipcRenderer;

export function RecentQuotes() {
    const navigate = useNavigate();
    const [recent_quoations, setResentQuotes] = useState(null)
    const edit_quotation = async (id) => {
        // const response = await fetch(process.env.REACT_APP_API_URL + '/api/edit/' + id);
        // const args = await response.json();
        navigate('/quotation', {
            state: {
                id:id
            }
        });
    };
    const delete_quotation = async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/quotation/' + id, {
            method: "DELETE"
        });
        const args = await response.json();
        var qts = recent_quoations.filter((v, i) => {
            return v.id != id
        });
        setResentQuotes(qts)
    };
    useEffect(() => {
        const getRecentQuotes = async () => {
            const resp = await fetch(process.env.REACT_APP_API_URL + '/api/quotations?limit=10&orderby=last_modified&order=desc');

            const recent_quotes = await resp.json();
            setResentQuotes(recent_quotes);
        };
        getRecentQuotes();
    }, [])


    return (
        <>
            <Spar />

            <div>

                <Breadcrumb className="py-2 bg-light px-1 border-top border-bottom shaddow">
                    <BreadcrumbItem onClick={() => {
                        navigate('/');
                    }} >
                        Home
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        Previous Quotations
                    </BreadcrumbItem>
                </Breadcrumb>

                {recent_quoations === null ? <>NO Recent Quotes</> : <Table striped hover size="sm">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>Customer</td>
                            <td>Quote Id</td>
                            <td>Dated</td>
                            <td>Created On</td>
                            <td>LastModifiedDate</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recent_quoations.map((v, i) => (
                                <tr className='' key={i} id={"q" + v.id}>
                                    <td> <a onClick={
                                        (e) => {

                                            delete_quotation(v.id);
                                        }
                                    } className='btn-link btn-primary p-1'><FontAwesomeIcon size='1x' icon={faRemove} />
                                    </a>
                                    </td>
                                    <td> <a onClick={(e) => {
                                        // ipcRenderer.send(channels.EDIT_QUOTATION, { index: i })
                                        edit_quotation(v.id);
                                    }}> <FontAwesomeIcon icon={faEdit} /></a></td>
                                    <td>{v.customer}</td>
                                    <td>{v.id}</td>
                                    <td>{v.dated}</td>
                                    <td>{new Date(v.time_created).toISOString().substring(0, 10)+" " + v.last_modified.substring(14, 21)}</td>
                                    <td>{new Date(v.last_modified).toISOString().substring(0, 10) + " " + v.last_modified.substring(14, 21)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                }
            </div>
        </>
    )
}