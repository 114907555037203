import React, { useState, useEffect } from "react";
import { Form, Button, BreadcrumbItem, Breadcrumb } from 'react-bootstrap';
import Spar from './spar';
import { useNavigate } from "react-router-dom";

// import { channels } from "../shared/constants";
// const electron = window.require('electron');
// const ipcRenderer = electron.ipcRenderer;

export function QuotationConfig() {
    const [toAddress, setToAddress] = useState('');
    const [customer, setCustomer] = useState('');

    // const [qname, setQName] = useState('');
    const [ref, setCustomerRef] = useState('')
    const [quote_date, setQuoteDate] = useState(new Date().toISOString().substring(0, 10))
    const navigate = useNavigate();
    const create_new_quote = async (data) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/create_new_quote', {
            method: 'POST',
            body: JSON.stringify(data)
        });
        const args = await response.json();
        navigate('/quotation', {
            state: {
                id:args.id
            }
        })
    };
    // useEffect(() => {

    //     ipcRenderer.on(channels.CREATE_NEW_QOUTATION, (e, args) => {
    //         navigate('/quotation', {
    //             state: {
    //                 args
    //             }
    //         })
    //     })
    //     return () => {
    //         ipcRenderer.removeAllListeners();
    //     };
    // }, [])

    return (
        <>
            <Spar />
            <Breadcrumb className="py-2 bg-light px-1 border-top border-bottom shaddow">
                <BreadcrumbItem href="/">
                    Home
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    Create New Quotation
                </BreadcrumbItem>
            </Breadcrumb>
            <div className='card card-body rounded-0 my-2'>

                <div className="d-flex justify-content-between">
                    <div>
                        <div className="input-group my-1">
                            <div className="input-group-preppend">Customer   :</div>
                            <Form.Control style={{ maxWidth: 300 }} className=" mx-2 rounded-0 me-0" size="sm" value={customer} onChange={(e) => { setCustomer(e.target.value) }} />
                        </div>
                        <div className="input-group my-1">
                            <div className="input-group-preppend">Quote Date   :</div>
                            <Form.Control type="date" style={{ maxWidth: 300 }} className=" mx-2 rounded-0 me-0" size="sm" value={quote_date} onChange={(e) => { setQuoteDate(e.target.value) }} />
                        </div>
                        {/* <div className="input-group mt-1">
                            <div className="input-group-preppend">Quote Name   :</div>
                            <Form.Control style={{ maxWidth: 300 }} className=" mx-2 rounded-0 me-0" size="sm" value={qname} onChange={(e) => { setQName(e.target.value) }} />
                        </div> */}
                        <div className="input-group mt-1">
                            <div className="input-group-preppend">Customer Reference   :</div>
                            <Form.Control style={{ maxWidth: 300 }} className=" mx-2 rounded-0 me-0" size="sm" value={ref} onChange={(e) => { setCustomerRef(e.target.value) }} />
                        </div>
                    </div>
                    <div>
                        <textarea value={toAddress} onChange={(e) => {
                            setToAddress(e.target.value)
                        }} placeholder="Enter Customer Address here" className="form-control h-100 w-100" ></textarea>
                    </div>
                </div>
                <div> <Button size="sm" className="my-2 rounded-0" onClick={() => {
                    if (customer !== '') {
                        create_new_quote({
                            customer: customer,
                            ref: ref,
                            to_address: toAddress,
                            // name: qname === '' ? toAddress : qname,
                            dated: quote_date,
                            time_created: new Date().toString(),
                            last_modified: new Date().toString(),
                            created_by: "Carron Muleya",
                            last_modified_by: "FirstName LastName"
                        });
                    } else {

                        alert('No Quotation Destination provided')
                    }
                }} variant="primary">Done</Button></div>
            </div>
        </>
    )
}