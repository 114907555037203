import React, { useEffect } from 'react';
import { Form, Button, Table, Container, Card } from 'react-bootstrap';
import { useState } from 'react';
import Spar from './spar';
import { channels } from '../shared/constants';
import SelectProduct from './SelectProduct';
import CurrencyChooser from './CurrencySelector';
import PrintQuotation from './PrintQuotation';
import PreviewQuotation from './PreviewQuotation';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

import { Error } from './alerts';

// const electron = window.require('electron');
// const ipcRenderer = electron.ipcRenderer;
export default function Quotation() {
    const [error, setError] = useState(null)
    let params = useLocation()
    const [show_currency_modal, selectCurrency] = useState(false);
    const [currency, setCurrency] = useState(['RTGS']);
    const [product, selectProduct] = useState(false);
    // const [selectedProduct, setSelectProduct] = useState();
    // const [selectProductPage,setSelectProductPage]=useState([0,7]);
    const [search, searchProduct] = useState(false);
    const [productValue, setProductValue] = useState('');
    const [productBarcode, setproductBarcode] = useState('');
    const [quote, setQuote] = useState();

    const [quotedProducts, addProductOnQuotation] = useState([]);
    const [Quantity, setQuantity] = useState(1);
    const [activeProduct, setactiveProduct] = useState([]);
    const [RandsRate, setRandsRate] = useState(54.47);
    const [usdRate, setusdRate] = useState(1020);
    const [RtgsTotal, setRtgsTotal] = useState(0);
    const [previewQuotation, setPreviewQuotation] = useState(false)
    const [myProducts, setProducts] = useState([]);

    /**functons */
    const update_currency = async (currency) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/update_currency');
        const args = await response.json();
    };
    const get_products = async () => {
        var products = localStorage.getItem('all_products');
        if (products) {
            setProducts(JSON.parse(products));
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/products');
        const args = await response.json();
        localStorage.setItem('all_products', JSON.stringify(args))
        setProducts(args)
    };
    const get_currency_rates = async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/currency_rates');
        const args = await response.json();
        setRandsRate(args.rands_rate)
        setusdRate(args.usd_rate)
    };
    const change_rand_rate = async (rate) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/change_rand_rate');
        const args = await response.json();
        setRandsRate(args.rands_rate)
    };
    const change_usd_rate = async (rate) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/change_usd_rate');
        const args = await response.json();
        setRandsRate(args.rands_rate)
    };
    const product_changed= async (product) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/quote/' + quote.id,
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(product), // body data type must match "Content-Type" header
            }
        );
        const args = await response.json();

        // addProductOnQuotation(args.products)
        setRtgsTotal(args.RtgsTotal);
    };
    const add_product_on_quote = async (product) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/quote/' + quote.id,
            {
                method: "PUT", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(product), // body data type must match "Content-Type" header
            }
        );
        const args = await response.json();

        addProductOnQuotation(args.products)
        setRtgsTotal(args.RtgsTotal);
        setQuantity(1)
        setactiveProduct({})
        setProductValue('')
        setproductBarcode('')
    };
    const get_quote = async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/quote/' + id);
        const q = await response.json();
        setQuote(q)
        addProductOnQuotation(q.products)
        setRtgsTotal(q.RtgsTotal);
    };
    const remove_product_on_quote = async (id) => {
        console.log("SJaj", id);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/quote/' + id, {
            method: "DELETE"
        });
        const args = await response.json();
        addProductOnQuotation(args.products)
        setRtgsTotal(args.RtgsTotal);
    };

    useEffect(() => {
        if (!show_currency_modal) {
            update_currency({ 'cu': currency });
        }
    }, [show_currency_modal])

    const navigate = useNavigate();
    useEffect(() => {
        if (params.state == null || params.state.id==undefined) {
            navigate('/');
        } else {
            get_quote(params.state.id);
        }
        get_products();
        
        // ipcRenderer.send(channels.GET_QUOTED_PRODUCTS, {});

        // ipcRenderer.send(channels.GET_PRODUCTS, {});
        // ipcRenderer.on(channels.GET_PRODUCTS, (e, args) => {
        //     setProducts(args.products)
        // })
        // ipcRenderer.on(channels.CHANGE_RANDS_RATE, (e, args) => {
        //     setRandsRate(args.rate)
        // })
        // ipcRenderer.on(channels.CHANGE_USD_RATE, (e, args) => {
        //     setusdRate(args.rate)
        // })

        // ipcRenderer.on(channels.ADD_PRODUCT_ON_QUOTATION, (e, args) => {
        //     addProductOnQuotation(args.products)
        //     setRtgsTotal(args.RtgsTotal);
        //     setQuantity(1)
        //     setactiveProduct({})
        //     setProductValue('')
        //     setproductBarcode('')
        // })
        // ipcRenderer.on(channels.REMOVE_PRODUCT_FROM_QUOTATION, (e, args) => {
        //     addProductOnQuotation(args.products)
        //     setRtgsTotal(args.RtgsTotal);
        // })
        // ipcRenderer.on(channels.REMOVE_PRODUCT_FROM_QUOTATION, (e, args) => {
        //     addProductOnQuotation(args.products)
        //     setRtgsTotal(args.RtgsTotal);
        // })
        // return () => {
        //     ipcRenderer.removeAllListeners();
        // };
    }, [])
    return (

        <div>
            {error !== null ? <Error onHide={() => {
                setError(null);
            }} message={error.message} /> : null}

            <div className=' d-flex justify-content-between'>
                <div> <Spar /></div>
                <div className='text-uppercase mt-1'>
                    <Card>
                        <Card.Body>
                            <p> Quotation To:<span className='border ps-1 py-1 ms-2 rounded muted fw-bold'>
                                <input type={'text'} className="border-0 muted fw-bold text-uppercase" defaultValue={quote != null ? quote.customer : null} />
                                {/* <FontAwesomeIcon className='text-primary px-1' icon={faEdit} /> */}
                            </span>

                            </p>
                            <p>Created Date:
                                <input type={'date'} className="border px-1 ms-3 rounded muted fw-bold" defaultValue={quote != null ? quote.dated : null} />
                            </p>

                        </Card.Body>
                    </Card>
                </div>
            </div>
            <CurrencyChooser show_currency_modal={show_currency_modal} setCurrency={(v) => { setCurrency(v) }}
                currency={currency} selectCurrency={(v) => { selectCurrency(v) }}
            />
            <Card className='mt-2'>
                <Card.Body>
                    <div className=' d-flex justify-content-between pt-1'>
                        <div>
                            <div className="input-group">
                                <div className="input-group-preppend">
                                    <label className="p-1">Product: </label>
                                    {/* <Button className="rounded-0 text-uppercase" onClick={() => { selectProduct(true) }} variant='primary' style={{ height: '33.5px' }} size='sm'>
                               
                            </Button> */}
                                </div>
                                <Form.Control onKeyUp={(e) => {
                                    // if (search) {
                                    //     searchProduct(false)
                                    //     setProductValue(e.target.value)
                                    //     searchProduct(true)
                                    // }
                                    if (e.target.value.length > 2) {
                                        if (e.key === 'ArrowDown') {
                                            searchProduct(true)
                                        }
                                    } else {
                                        searchProduct(false)
                                    }

                                }} className='rounded-0' value={productValue} onChange={(e) => {

                                    setProductValue(e.target.value)

                                }} name='productOrderCode' style={{ width: '206.66px', height: '33.5px', marginLeft: "2.66px" }} size='sm' />
                                <div className="input-group-append">
                                    <label className="p-1 small text-capitalize">{activeProduct.Name}</label>
                                    {/* <Button className="rounded-0 text-uppercase" onClick={() => { selectProduct(true) }} variant='primary' style={{ height: '33.5px' }} size='sm'>
                               
                            </Button> */}
                                </div>
                            </div>
                        </div>
                        <div className="text-uppercase px-2" style={{ fontWeight: 'bolder' }}>
                            <div className="input-group">
                                <div className="input-group-preppend">
                                    <label className="p-1">Currency: </label>
                                </div>
                                <Form.Control readOnly className='rounded-0' value={currency} placeholder="Currency" size='sm' />
                                <div className="input-group-append">
                                    <Button className="rounded-0 text-uppercase" onClick={() => selectCurrency(true)} style={{ fontWeight: 'bolder' }} variant='primary' size='sm'>+</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {search ? <Container fluid className='mt-1 shadow bg-white' style={{ zIndex: 1000, overflowY:"auto",height:'300px', position: "absolute", width: "1200px", maxWidth: 670 }}>
                        <div className='py-1'><FontAwesomeIcon className='float-end fa-2x' onClick={()=>{
                            searchProduct(false)
                            setProductValue('')
                        }} icon={faTimes}/></div>
                        <Table striped hover >
                            <tbody>

                                {myProducts == null ? '' : myProducts.filter(
                                    e => (e.Name.toLowerCase().match(productValue.toLowerCase()) || e.Name.toLowerCase().includes(' ' + productValue.toLowerCase() + ' ' || (new RegExp(productValue.toLowerCase(), 'g').test(e.Name))))
                                ).map((item) => (
                                    <tr onClick={() => {
                                        setProductValue(item['Order Code'])
                                        setproductBarcode(item.BarCode)
                                        item['qty'] = Quantity
                                        setactiveProduct(item)
                                        searchProduct(false)
                                    }} className='product-line' style={{ height: '10 !important', padding: '0 !important' }} key={item['On Order'] + Math.random()}>
                                        <td>
                                            {item.Name}
                                        </td>
                                        <td>{item['Order Code']}</td>
                                        <td>Sell: {item['Selling Price']}</td>
                                        <td>On Hand: {item['On Hand']}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                    </Container> : null}
                    {product === false ? null : <SelectProduct myProducts={myProducts} callBack={(p) => {
                        setProductValue(p['Order Code'])
                        setproductBarcode(p.BarCode)
                        p['qty'] = Quantity
                        setactiveProduct(p)
                        selectProduct(false)
                    }} hide={() => {
                        selectProduct(false)
                    }} />
                    }
                    <div className=''>
                        <div className=' d-flex justify-content-between '>

                            <div>
                                <div className="input-group my-1">
                                    <div className="input-group-preppend">
                                        <label className="p-1">Barcode: </label>
                                        <Button className="rounded-0 text-uppercase" onClick={() => { selectProduct(true) }} variant='primary' style={{ height: '33.5px' }} size='sm'>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </div>
                                    <Form.Control onChange={() => {

                                    }} value={productBarcode} style={{ maxWidth: '400px', height: '33.5px', margin: '0' }} className='rounded-0' name='productBarcode' size='sm' />
                                </div>
                                <div className="input-group my-1">
                                    <div className="input-group-preppend">
                                        <label className="p-1">Quantity: </label>
                                    </div>
                                    <Form.Control type='number' value={Quantity} onChange={(e) => {
                                        setQuantity(e.target.value)
                                    }} className='rounded-0' name='Quantity' size='sm' />
                                </div>
                                <div className="input-group my-1">
                                    <div className="input-group-preppend">
                                        <label className="p-1">Selling Prize: </label>
                                    </div>
                                    <Form.Control type='number' value={activeProduct['Selling Price']} onChange={(e) => {
                                        let copy = activeProduct;
                                        copy['Selling Price'] = e.target.value;
                                        setactiveProduct(copy);
                                    }} className='rounded-0' name='Quantity' size='sm' />
                                </div>
                            </div>

                            <div className="text-uppercase px-2" style={{ fontWeight: 'bolder' }}>

                                {
                                    (() => {
                                        if (currency.includes('RANDS')) {
                                            return (
                                                <div className="input-group my-1">
                                                    <div className="input-group-preppend">
                                                        <label className="p-1">Rand RATE: </label>
                                                    </div>
                                                    <Form.Control type='number' onChange={e => {
                                                        // ipcRenderer.send(channels.CHANGE_RANDS_RATE, { 'rate': e.target.value })
                                                        change_rand_rate(e.target.value);
                                                    }} className='rounded-0' value={RandsRate} placeholder="Rate" size='sm' />

                                                </div>
                                            )
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (currency.includes('USD')) {
                                            return (
                                                <div className="input-group my-1">
                                                    <div className="input-group-preppend">
                                                        <label className="p-1">USD RATE: </label>
                                                    </div>
                                                    <Form.Control type='number' className='rounded-0' value={usdRate} onChange={
                                                        e => {
                                                            // ipcRenderer.send(channels.CHANGE_USD_RATE, { 'rate': e.target.value })
                                                            change_usd_rate(e.target.value);
                                                        }
                                                    } placeholder="Rate" size='sm' />

                                                </div>
                                            )
                                        }
                                    })()
                                }

                            </div>
                        </div>
                        <div className='text-center '>
                            <Button variant='primary' onClick={
                                (e) => {
                                    if (productBarcode !== '') {
                                        activeProduct.qty = Quantity
                                        // quotedProducts.push(activeProduct)
                                        // addProductOnQuotation(quotedProducts)
                                        // ipcRenderer.send(channels.ADD_PRODUCT_ON_QUOTATION, { 'product': activeProduct })
                                        add_product_on_quote(activeProduct);
                                        //var t = 0;
                                        // quotedProducts.forEach(item => {
                                        //     t += item['Selling Price']
                                        // });
                                        // setRtgsTotal(t);

                                    } else {
                                        setError({ message: "No product Selected", header: "INPUT ERROR!" })
                                    }
                                }
                            } size='sm'>Add(F9)</Button>
                            <Button variant='danger' className='mx-2' onClick={
                                () => {
                                    setPreviewQuotation(true)
                                }
                            } size='sm'>Preview Quotation</Button>

                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* {search ? <SearchProduct myProducts={myProducts} search={productValue} callBack={(p) => {
                setProductValue(p['Order Code'])
                setproductBarcode(p.BarCode)
                p['qty'] = Quantity
                setactiveProduct(p)
                searchProduct(false)
            }} /> : null} */}

            <PreviewQuotation
                currency={currency} delete={(item, i) => {

                    // ipcRenderer.send(channels.REMOVE_PRODUCT_FROM_QUOTATION, { product: item })

                    remove_product_on_quote(item.id);
                }} quotedProducts={quotedProducts}
                RtgsTotal={RtgsTotal} usdRate={usdRate}
                RandsRate={RandsRate} afterProdModification={(p, i) => {
                product_changed(p);

                }}
            />


            {previewQuotation === false ? null : <PrintQuotation setPreviewQuotation={(v) => { setPreviewQuotation(v) }}
                currency={currency} quotedProducts={quotedProducts} quote={quote}
                previewQuotation={previewQuotation} RtgsTotal={RtgsTotal} usdRate={usdRate}
                RandsRate={RandsRate} />
            }
        </div >

    )
}