
import React, { useEffect, useState } from 'react';
import Spar from './spar';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Modal, Row, Col, Table } from 'react-bootstrap';

// const handleQuit = () => {
//     ipcRenderer.invoke(channels.QUIT);
// };


function Home() {

    const navigate = useNavigate();
    const [recent_quoations, setResentQuotes] = useState(null)

    useEffect(() => {
        // ipcRenderer.send(channels.GET_RECENT_QUOTATIONS, { max: 10 })
        // ipcRenderer.on(channels.GET_RECENT_QUOTATIONS, (e, args) => {
        //     setResentQuotes(args);

        // })
        // ipcRenderer.on(channels.EDIT_QUOTATION, (e, args) => {

        // navigate('/quotation', {
        //     state: {
        //         args
        //     }
        // });
        // })
        const getRecentQuotes = async () => {
            const resp = await fetch(process.env.REACT_APP_API_URL + '/api/quotations?limit=10&orderby=last_modified&order=desc');

            const recent_quotes = await resp.json();
            setResentQuotes(recent_quotes);
        };
        getRecentQuotes();

    }, [])

    return (

        <>
            
            <Spar />
            <div className='wrapper'>
                <div className='text-center my-5'>
                    <Button variant="primary" onClick={() => {
                        navigate('/config_quotation');
                    }} className='m-1' size='sm'>
                        Create New Quotation
                    </Button>

                    <Button variant='danger' onClick={() => {
                        navigate('/existing_quotes');
                    }} className='m-1' size='sm'>
                        Edit Existing Quotation
                    </Button>
                </div>
                <p className='h5 mt-5'>
                    Recent Quotations
                </p>
                {recent_quoations === null ? <>NO Recent Quotes</> : <Table striped hover size="sm">
                    <thead >
                        <tr >

                            <td>Customer</td>
                            <td>Quote Id</td>
                            <td>Dated</td>
                            <td>Created On</td>
                            <td>LastModifiedDate</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recent_quoations.map((v, i) => (
                                <tr className='mouse-pointer mouse-pointer-blue' onClick={(e) => {
                                    // ipcRenderer.send(channels.EDIT_QUOTATION, { index: i })

                                    navigate('/quotation', {
                                        state: {
                                            id: v['id']
                                        }
                                    });
                                }} key={i}>
                                    <td>{v.customer}</td>
                                    <td>{v.id}</td>
                                    <td>{v.dated}</td>
                                    <td>{new Date(v.time_created).toISOString().substring(0, 10) + " " + v.time_created.substring(16, 21)}</td>
                                    <td>{new Date(v.last_modified).toISOString().substring(0, 10) + " " + v.last_modified.substring(16, 21)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                }
            </div>
        </>

    )
}
export default Home;