import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Row, Col, Table } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function EditProduct(props) {
    const [activeProduct, setactiveProduct] = useState(props.product[0])
    const [hide,hideProdEditor]=useState(false);
  
    useEffect(() => {
       if(hide!==false){
        props.onClose();
       }
    }, [hide])
    return (
        <Modal className='rounded-0' data-backdrop="static" data-keyboard="false" tabIndex="-1" size='xxlg' show={hide===false} onHide={() => {
            hideProdEditor(true)
        }} >
            <Modal.Header closeButton className='p-1 rounded-0'>
                Edit product
            </Modal.Header>
            <Modal.Body>
                <div className=' d-flex justify-content-between '>

                    <div>
                        <div className="input-group my-1">
                            <div className="input-group-preppend">
                                <label className="p-1">Barcode: </label>
                            </div>
                            <Form.Control disabled defaultValue={activeProduct.BarCode} style={{ maxWidth: '400px', height: '33.5px', margin: '0' }} className='rounded-0' name='productBarcode' size='sm' />
                        </div>
                        <div className="input-group my-1">
                            <div className="input-group-preppend">
                                <label className="p-1">Quantity: </label>
                            </div>
                            <Form.Control type='number' defaultValue={activeProduct.qty} onChange={(e) => {
                                let prod = activeProduct;
                                prod.qty = e.target.value;
                                setactiveProduct(prod)
                            }} className='rounded-0' name='Quantity' size='sm' />
                        </div>
                        <div className="input-group my-1">
                            <div className="input-group-preppend">
                                <label className="p-1">Selling Prize: </label>
                            </div>
                            <Form.Control type='number' defaultValue={activeProduct['Selling Price']} onChange={(e) => {
                                let copy = activeProduct;
                                copy['Selling Price'] = e.target.value;
                                setactiveProduct(copy);
                            }} className='rounded-0' name='Quantity' size='sm' />
                        </div>
                    </div>

                    <div className="text-uppercase" style={{ fontWeight: 'bolder' }}>

                    </div>
                </div>
               <div className='d-flex justify-content-center'> <Button size='sm' variant='primary' className='m-1' onClick={(e)=>{
                    props.onSave(activeProduct);
                    // hideProdEditor()
                }}>Save</Button>
                 <Button size='sm' variant='success' className='m-1' onClick={(e)=>{
                    
                    hideProdEditor(true)
                    props.onSave(activeProduct,props.product[1]);
                }}>Done</Button></div>
            </Modal.Body>
        </Modal>
    )
}