module.exports = {
    channels: {
      GET_DATA: 'get_data',
      GET_RECENT_QUOTATIONS:'get_recent_quotes', 
      QUIT:'quit' ,
      CREATE_NEW_QOUTATION:"create_new_quote",
      CHANGE_USD_RATE:'CHANGE_USD_RATE',
      CHANGE_RANDS_RATE:"change_rands_rate",
      ADD_PRODUCT_ON_QUOTATION:'add_on_quotation',
      UPDATE_CURRENCY:'update_curency',
      GET_QUOTED_PRODUCTS:'GET_Q',
      GET_PRODUCTS:"GET_ALL_PRODUCTS",
      EDIT_QUOTATION:'edit_quote',
      REMOVE_PRODUCT_FROM_QUOTATION:"remove_prod_from_quote",
      MODIFY_QUOTED_PRODUCT:"modify_doc_djjdncjdn",
      DELETE_QUOTATION:"DELETE_QUOTE",
    },
  };