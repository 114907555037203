import React from 'react';
import SparLogo from "../SparLogo.png"
import {Image } from 'react-bootstrap';

 
export default function Spar() {
    return (<>
        <Image src={SparLogo}>

        </Image>
        <p className='text-uppercase m-2 mt-0' variant="" style={{ fontWeight: 'bolder' }}>Better Together</p></>)
}